import { useEffect } from "react";
import Button from "components/atoms/Button";
import { useAppSelector } from "redux/hooks";
import SubscriptionService from "services/subscription.service";

export default function UpdateBookingConfirmationForm() {
  const data = useAppSelector((state) => state.modal.data);

  useEffect(() => {
    return () => {
      SubscriptionService.unsubscribe();
    };
  }, []);

  const onClickYes = () => SubscriptionService.call();

  return (
    <div>
      <h3>{data.heading}</h3>
      <p>{data.message}</p>
      <div className="card-main">
        <div className="card-box">
          <div style={{ marginBottom: "16px" }}>
            <div className="card-inner-price">
              <p className="card-inner-price-heading">Old Price</p>
              <p className="card-inner-price-amount">
                {data.oldAmount?.toFixed(2)} SAR
              </p>
            </div>
            <div className="card-inner-price">
              <p className="card-inner-price-heading">Amount Difference</p>
              <p className="card-inner-price-amount">
                {data.amountDifference?.toFixed(2)} SAR
              </p>
            </div>
            <div className="card-inner-price">
              <p className="card-inner-price-heading">New Price</p>
              <p className="card-inner-price-amount">
                {data.newAmount?.toFixed(2)} SAR
              </p>
            </div>
          </div>
        </div>
      </div>
      <Button variant="contained" onClick={onClickYes}>
        Confirm
      </Button>
    </div>
  );
}
